import React from "react";

// Define a type for footer link items
interface FooterLink {
  label: string;
  url: string;
}

// Example footer links
const footerLinks: FooterLink[] = [
  { label: "Home", url: "#" },
  { label: "About Us", url: "#about" },
  { label: "Services", url: "#services" },
  { label: "Contact", url: "#contact" },
];

const Footer: React.FC = () => {
  return (
    <footer style={footerStyle}>
      <div style={containerStyle}>
        {/* Footer navigation links */}
        <div>
          <h3>Quick Links</h3>
          <ul style={listStyle}>
            {footerLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url} style={linkStyle}>
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Contact info */}
        <div>
          <h3>Contact Us</h3>
          <p>Email: asahmakerspace@gmail.com</p>
          <p>Phone: +233 54 9120 744 / +233 24 7665 872 / +233 54 6395 282</p>
        </div>

        {/* Social media icons */}
        <div>
          <h3>Follow Us</h3>
          <ul style={socialListStyle}>
            <li>
              <a
                href="https://www.instagram.com/aslan_robotics.official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                style={socialIconStyle}
              >
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Copyright section */}
      <div style={copyrightStyle}>
        <p>
          &copy; {new Date().getFullYear()} ASAH Maker-Space. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

// Footer styles
const footerStyle: React.CSSProperties = {
  backgroundColor: "#222",
  color: "#fff",
  padding: "20px",
  marginTop: "auto",
  textAlign: "center",
};

const containerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexWrap: "wrap",
  maxWidth: "1200px",
  margin: "0 auto",
};

const listStyle: React.CSSProperties = {
  listStyleType: "none",
  padding: 0,
};

const linkStyle: React.CSSProperties = {
  color: "#fff",
  textDecoration: "none",
  marginBottom: "10px",
  display: "block",
};

const socialListStyle: React.CSSProperties = {
  listStyleType: "none",
  padding: 0,
  display: "flex",
  justifyContent: "center",
};

const socialIconStyle: React.CSSProperties = {
  margin: "0 10px",
  color: "#fff",
  textDecoration: "none",
};

const copyrightStyle: React.CSSProperties = {
  borderTop: "1px solid #444",
  paddingTop: "10px",
  marginTop: "20px",
  fontSize: "14px",
};

export default Footer;
