import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import L from "leaflet";

// Define the type for position coordinates (latitude, longitude)
interface Coordinates {
  lat: number;
  lng: number;
}

// Custom icon to fix marker icon issue (default icon may not display correctly)
const markerIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41],
});

const center: Coordinates = {
  lat: 7.350516,
  lng: -2.3215092,
};

const OSMMap: React.FC = () => {
  return (
    <MapContainer
      center={center}
      zoom={13}
      style={{ height: "400px", width: "100%" }}
    >
      {/* OpenStreetMap tile layer */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Marker at the center */}
      <Marker position={center} icon={markerIcon}>
        <Popup>ASAH Maker-Space</Popup>
      </Marker>
    </MapContainer>
  );
};

export default OSMMap;
