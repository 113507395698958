import { Col } from "react-bootstrap";

interface ServiceCardProps {
  serviceImage: string;
  serviceImageAlt: string;
  serviceTitle: string;
  serviceDescription: string;
}

function ServiceCard({
  serviceImage,
  serviceImageAlt,
  serviceTitle,
  serviceDescription,
}: ServiceCardProps) {
  return (
    <Col md={4} my-2>
      <img
        src={serviceImage}
        alt={serviceImageAlt}
        className="img-fluid"
        style={{ height: "250px", width: "100%", objectFit: "cover" }}
      />
      <br />
      <br />
      <h4>{serviceTitle}</h4>
      <p>{serviceDescription}</p>
    </Col>
  );
}

export default ServiceCard;
