import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

// Define the structure of the form data
interface FormData {
  email: string;
  message: string;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const EmailForm: React.FC = () => {
  const formik = useFormik<FormData>({
    initialValues: {
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        // Simulate backend request (replace with actual API URL)
        const response = await axios.post(
          "https://example.com/api/send-email",
          values,
        );
        console.log("Response:", response.data);
        alert("Your message has been sent!");
        resetForm(); // Reset the form after successful submission
      } catch (error) {
        console.error("Error sending message:", error);
        alert("Failed to send the message. Please try again.");
      } finally {
        setSubmitting(false); // Disable the submitting state
      }
    },
  });

  return (
    <div>
      <h4>Let's Connect</h4>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Important for validation feedback
          />
          {formik.touched.email && formik.errors.email ? (
            <span style={{ color: "red" }}>{formik.errors.email}</span>
          ) : null}
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.message && formik.errors.message ? (
            <span style={{ color: "red" }}>{formik.errors.message}</span>
          ) : null}
        </div>
        <button type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
};

export default EmailForm;
