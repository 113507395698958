import { useState } from "react";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import logo from "./images/logo.png";
import automationImage from "./images/automationImage.jpg";
import roboticsImage from "./images/robotics.jpg";
import dPrintingImage from "./images/3dPrinting.jpg";
import animation from "./images/animationImage.jpg";
import model from "./images/model.jpg";
import coding from "./images/coding.jpg";
import construction from "./images/construction.jpg";
import droneAssembly from "./images/droneAssembly.jpg";
import training from "./images/training.jpg";
import aboutUs from "./images/aboutUs.jpg";
import "./App.css";

// Import components
import EmailForm from "./components/emailForm";
import Map from "./components/mapDirection";
import Footer from "./components/footer";
import ServiceCard from "./components/serviceCard";

interface ServiceCardProps {
  serviceImage: string;
  serviceImageAlt: string;
  serviceTitle: string;
  serviceDescription: string;
}

const serviceCards: ServiceCardProps[] = [
  {
    serviceImage: automationImage,
    serviceImageAlt: "Automation Image",
    serviceTitle: "Automation",
    serviceDescription:
      "Custom automation systems for industries using AI and machine learning.",
  },
  {
    serviceImage: roboticsImage,
    serviceImageAlt: "Robotics Image",
    serviceTitle: "Robotics",
    serviceDescription:
      "Custom robots for various applications, including industrial automation.",
  },
  {
    serviceImage: dPrintingImage,
    serviceImageAlt: "3D Printing Image",
    serviceTitle: "3D Printing",
    serviceDescription:
      "Highly precise 3D printed parts for industries like automotive and aerospace.",
  },
  {
    serviceImage: animation,
    serviceImageAlt: "3D Animation Image",
    serviceTitle: "3D Animation",
    serviceDescription:
      "Stunning animations for marketing, educational videos, and more.",
  },
  {
    serviceImage: model,
    serviceImageAlt: "3D Designing Image",
    serviceTitle: "3D Designing",
    serviceDescription:
      "Customized 3D models for various industries, from manufacturing to architecture",
  },
  {
    serviceImage: coding,
    serviceImageAlt: "Software Development Image",
    serviceTitle: "Software Development",
    serviceDescription:
      "Custom software solutions using the latest programming languages.",
  },
  {
    serviceImage: training,
    serviceImageAlt: "Training School Image",
    serviceTitle: "Training School",
    serviceDescription:
      "Hands-on training in automation, robotics, 3D printing, and more.",
  },
  {
    serviceImage: construction,
    serviceImageAlt: "Construction Image",
    serviceTitle: "Construction",
    serviceDescription:
      "Streamlined construction processes using automation and 3D printing.",
  },
  {
    serviceImage: droneAssembly,
    serviceImageAlt: "Drone Assembly Image",
    serviceTitle: "Drone Assembly",
    serviceDescription:
      "Expert drone assembly services for customized builds, ensuring precision, performance, and quick setup for all your aerial needs.",
  },
];

function App() {
  // State to track the active link
  const [activeLink, setActiveLink] = useState<string>("");

  // Function to handle active link change
  const handleLinkClick = (link: string) => {
    setActiveLink(link);
  };
  return (
    <>
      <link rel="icon" href={logo} />

      {/* Header Section */}
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        className="navbar"
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="#home" className="text-white">
            <img src={logo} alt="Company Logo" width="50" height="50" />
            ASAH Maker-Space
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                href="#services"
                className={`nav-link ${activeLink === "services" ? "active-link" : ""}`}
                onClick={() => handleLinkClick("services")}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="#about"
                className={`nav-link ${activeLink === "about" ? "active-link" : ""}`}
                onClick={() => handleLinkClick("about")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="#mission"
                className={` nav-link ${activeLink === "mission" ? "active-link" : ""}`}
                onClick={() => handleLinkClick("mission")}
              >
                Mission & Vision
              </Nav.Link>
              <Nav.Link
                href="#contact"
                className={` nav-link ${activeLink === "contact" ? "active-link" : ""}`}
                onClick={() => handleLinkClick("contact")}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* About Us Section */}
      <section id="home" className="bg-light aboutUsBgImage">
        <Row className="align-items-center justify-content-center">
          <Container>
            <div className="aboutUsInfo">
              <p className="text-start">
                Transforming Ideas Into
                <br />
                Reality With
                <br />
                Advanced Technology
              </p>
            </div>
          </Container>
        </Row>
      </section>

      {/* Services Section */}
      <section id="services" className="py-5">
        <Container>
          <h2 className="text-center">Our Services</h2>

          <Row className="text-center mt-4">
            {serviceCards.map((Card) => (
              <ServiceCard
                serviceTitle={Card.serviceTitle}
                serviceImage={Card.serviceImage}
                serviceImageAlt={Card.serviceImageAlt}
                serviceDescription={Card.serviceDescription}
              />
            ))}
          </Row>
        </Container>
      </section>

      <section id="about" className="py-5 bg-light">
        <Container>
          <Row className="text-center">
            <Col
              md={6}
              className="d-flex flex-column justify-content-center py-auto"
            >
              <h2>About Us</h2>
              <p>
                At ASAH Maker-Space, we are dedicated to delivering the highest
                quality services, grounded in the principles of safety, quality,
                and environmental sustainability. Our experienced team of
                engineers, designers, and educators is committed to providing
                exceptional service tailored to your needs. Based in Sunyani, we
                also engage in educational boot camps as a traveling university.
              </p>
            </Col>
            <Col md={6}>
              <img
                src={aboutUs}
                alt="about us"
                className="img-fluid"
                style={{ height: "500px", width: "100%", objectFit: "cover" }}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mission and Vision Section */}
      <section id="mission" className="py-5 bg-light">
        <Container>
          <Row className="text-center">
            <Col md={6}>
              <h4>Our Mission</h4>
              <p>
                To provide innovative and sustainable technology solutions to
                our clients using the latest advancements in automation,
                robotics, and more.
              </p>
            </Col>
            <Col md={6}>
              <h4>Our Vision</h4>
              <p>
                To be a leading technology firm recognized for our expertise and
                commitment to sustainable solutions.
              </p>
            </Col>
          </Row>
          <Row className="text-center my-5">
            <Col md={6}>
              <h2>+2300</h2>
              <p>Students Trained</p>
            </Col>
            <Col md={6}>
              <h2>+21</h2>
              <p>Projects Completed</p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-5 ">
        <Container>
          <h2 className="text-center">Contact Us</h2>
          <Row>
            <Col md={6}>
              <Map />
            </Col>
            <Col md={6}>
              <EmailForm />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default App;
